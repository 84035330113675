export const environment = {
  production: false,
  apiUrl: 'https://bieno-da12-d-902772-webapi-01.azurewebsites.net',
  //apiUrl: 'http://localhost:8000',
  hardCoreExcelSheet: '/assets/files/QA/GMI 2 - Live connection to the data.xlsx',
  azure: {
    clientId: '5514cf91-185e-424c-ac80-66ea621fde72',
    tenantID: 'f66fae02-5d36-495b-bfe0-78a6ff9f8e6e',
  },
  powerBi: {
    groupId: '9d7d5126-b2e1-4da8-a02f-3828347a7289',
    // reportId:"02e47bac-cd2c-41b5-8b7c-63cf7a63ea9e"
    reportId: 'bf3ca942-af47-4021-858c-81e198423edd',
    groupIdCL: 'a989caab-dc1d-4ff5-ab08-b973b5901d01',
    // reportId:"02e47bac-cd2c-41b5-8b7c-63cf7a63ea9e"
    datasetIdCL_Daily: 'e33a16ce-1da0-4424-9c62-f2b89a78d45e',
    datasetIdCL_Monthly: 'db854583-dec7-4299-aebf-ccf9fadd7920',
    datasetId_PC:'1538b5f3-8983-4799-a0fe-f2fdd7bdf5a0',
    datasetId: 'f5a2f711-1967-4e9b-b7da-f0d48c0dd59c',
    monthlyReportId: '98284b41-e8b6-44fb-a968-d02a2204b86f',
    bwDatasetId: 'ed5b9249-af92-4c9c-9bfe-f9ed89c5cd11',
    bwdummyDatasetID:'d416014c-7c59-4e8b-addf-799bc4bc92a4',
    //datasetId: 'aa901bf3-2c6c-497f-8438-450dc0848404',
    distributionGrowthReportId: '701cfa0c-542c-45be-8f41-65a135c296a2',
  },
  appInsights: {
    instrumentationKey: 'c220184c-ef82-4734-ac78-f5e8e9eba41f',
    connectionString: 'InstrumentationKey=c220184c-ef82-4734-ac78-f5e8e9eba41f;IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/;LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/',
  },
  dashboard : {
    businessWinning : 'https://app.powerbi.com/groups/me/apps?ctid=f66fae02-5d36-495b-bfe0-78a6ff9f8e6e',
    competitiveWeighted : "https://powerhouse.unilever.com/product/CompetitivenessTOWeightedMarketShare?id=7",
    globalDistribution : 'https://app.powerbi.com/groups/c0fb089c-10d2-4943-b67e-6be58a406c48/reports/d8ae34a1-51cf-4df4-a0f1-a833b45cce55/ReportSection6b02265c2a8355062481',
    pentrack :'https://app.powerbi.com/groups/me/reports/ef4b835c-5457-45ed-b947-5f103304cfe9/ReportSectionee8863bff14a68964a7c',
    channelDashboard : 'https://app.powerbi.com/groups/me/reports/150a61a4-fb3e-40a3-97b2-6fd87476c86e/ReportSection38c67eb69a40b167774e',
    innovations : 'https://app.powerbi.com/groups/me/apps/d62fd126-d428-4437-9e3a-692c3932ad5d/reports/024d7835-5d49-47e0-a0ca-5543d9c6ac77/ReportSection3e6461c494a5027bb175',
    brandHealth : 'https://app.powerbi.com/groups/deaa6cb2-8111-4a2f-975b-4d1009f2f772/reports/d9e26b23-ac0b-4458-a907-3d057ac5df3d/ReportSection'
  },
  FabCleaning:{
    totalMarket:'https://app.powerbi.com/groups/9d7d5126-b2e1-4da8-a02f-3828347a7289/reports/05f88e45-39d2-445f-b1f8-dde40a71a417/ReportSection4422ac172780ad580b04?experience=power-bi',
    MarketGrowthbyFormat:'https://app.powerbi.com/groups/9d7d5126-b2e1-4da8-a02f-3828347a7289/reports/a4c3ccec-af45-4879-90b2-ee5aad494b6a/ReportSection18a925609015843900e6?experience=power-bi',
    MarketShareDevelopmentbyFormat:'https://app.powerbi.com/groups/9d7d5126-b2e1-4da8-a02f-3828347a7289/reports/58ef2512-ac7b-486a-8598-834b850a8e5b/ReportSectioncbedcba58e9c4d991589?experience=power-bi',
    FormatsTopManufacturersandBrands:'https://app.powerbi.com/groups/9d7d5126-b2e1-4da8-a02f-3828347a7289/reports/bf5a3aba-189a-42af-b22a-bc6b30606ffb/ReportSectione433edd2c3ada2d5860d?experience=power-bi',
    FormatsMarketSummary:'https://app.powerbi.com/groups/9d7d5126-b2e1-4da8-a02f-3828347a7289/reports/36659e9e-bca5-46ad-8bd1-43aac64559b7/ReportSectioned62ef73908b18536825?experience=power-bi'
  },
  footerLinks : {
    reportingPeriods: 'https://app.powerbi.com/groups/c0fb089c-10d2-4943-b67e-6be58a406c48/reports/c152c1ab-ea23-4a3b-a838-0dff4f01b3b2/ReportSection3959a6b70cf7546eff85',
    methodology: 'https://unilever.sharepoint.com/:p:/r/sites/GMI2.0/Shared%20Documents/Supporting%20Materials/GMI%202.0%20-%20Methodology.pptx?d=w39b558430bcd45d1b4f516cd50d7e9e4&csf=1&web=1&e=tpcvxF',
    userGuide: 'https://unilever.sharepoint.com/:f:/r/sites/GMI2.02/Shared%20Documents/User%20Materials?csf=1&web=1&e=NDc7tB',
    faq: 'https://unilever.sharepoint.com/:p:/r/sites/GMI2.0/Shared%20Documents/Supporting%20Materials/GMI%202.0%20-%20Frequently%20Asked%20Questions.pptx?d=w2c21412ec9cb4f0ab5515e9d6522c345&csf=1&web=1&e=ew3KOc',
    reportAnIssue: 'https://unilever.service-now.com/esc?id=sc_cat_item&table=sc_cat_item&sys_id=a0d149fa1b0c1910abe6db98bd4bcb4d',
    reportingNotes : 'https://unilever.sharepoint.com/:b:/r/sites/GMI2.02/Shared%20Documents/Release%20Notes/GMI%202.0%20-%20Monthly%20Reporting%20Data%20Release%20-%20Inclusions%20Exclusions%20Other%20Changes.docx?csf=1&web=1&e=q0iQ64'
  },
  functionAppUrl:'https://bieno-da12-d-902772-un-fun-04.azurewebsites.net/api/DAX?code=JeupY50PcUxVk3W9WwI5Gz_IL-5XZ98-MkweRqmYYRqDAzFuCFv6yA==',
  functionDataSource:'asazure://northeurope.asazure.windows.net/bienoda12d902772unilevercomas01;Initial Catalog=GMI_AAS_20230131'
};