import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { MsalService } from '@azure/msal-angular';
import { AppInsightsService } from './services/app-insights.service';
// import { MyMonitoringService } from './services/appinsights.service'
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = 'GMI App';

  constructor(private titleService: Title, private appInsightsService: AppInsightsService, private bnIdle: BnNgIdleService, private msalService: MsalService) {}

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.bnIdle.startWatching(3600).subscribe((res) => {
      if (res) {
        console.log('The  session  expired');
        //this.router.navigateByUrl('logout');
        this.msalService.logout();
      }
    });
  }
}
