import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from '../../environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppInsightsService {

  appInsights: ApplicationInsights;
  constructor(private authService: AuthService) {
    this.appInsights = new ApplicationInsights({
      config: {
        // instrumentationKey: environment.appInsights.instrumentationKey,
        connectionString: environment.appInsights.connectionString,
        enableAutoRouteTracking: true // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
    this.authService.activeUser.subscribe((res) => {
      console.log("result for user mail details",res);
      console.log("user email",res?.username);
      if (res?.username) {
        var userEmail = res?.username;
        this.appInsights.setAuthenticatedUserContext(userEmail);
      }
    },
      (error) => {
        console.log(error);
      });
  }

  logPageView(name?: string, url?: string) { // option to call manually
    this.appInsights.trackPageView({
      name: name,
      uri: url
    });
  }

  logEvent(name: string, properties:any) {
    this.appInsights.trackEvent({ name: name}, properties);
  }

  logMetric(name: string, average: number, properties?: { [key: string]: any }) {
    this.appInsights.trackMetric({ name: name, average: average }, properties);
  }

  logException(exception: Error, severityLevel?: number) {
    this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.appInsights.trackTrace({ message: message}, properties);
  }
}
