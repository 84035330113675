import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import {AppInsightsService} from 'src/app/services/app-insights.service';

@Injectable()
export class MSALInterceptorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private appInsightService: AppInsightsService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error?.headers?.get('x-powerbi-error-info') === 'TokenExpired' ||
          error?.error?.code === 'TokenExpired'
        ) {
          this.authService
            .refreshToken()
            .then((res) => {
              location.reload();
            })
            .catch((err) => {
              console.log("LogOff Issue Analysis::", err);
              this.appInsightService.logException(err, 3);
              this.authService.signOut();
            });
        }
        return throwError(error);
      })
    );
  }
}
